import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Spinner } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    ChartOptions
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);

export class ProcessChartCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card className="card-chart">
                <CardHeader>
                    <CardTitle tag="h5">Requests</CardTitle>
                    <p className="card-category">{this.props.filterText + " Requests"}</p>
                </CardHeader>
                <CardBody>
                    {this.props.loading ?
                        <Spinner>
                            Loading...
                        </Spinner> :
                        <Line
                            data={this.props.chartData}
                            options={this.props.chartOptions}
                            width={400}
                            height={100}
                            itemType='line'
                            typeof='line'
                            redraw={true}
                        />
                    }
                </CardBody>
            </Card>
        );
    }
}