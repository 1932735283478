import { stringify } from 'ajv';
import React, { Component } from 'react';
import { Button } from 'reactstrap';


export class CsvDownloadButton extends Component {
    constructor(props) {
        super(props);
    }

    handleClick = () => {

        let data = JSON.stringify(this.props.report_data);
        let clientId = this.props.clientId;
        let carrierCode = this.props.carrierCode;

                fetch('reportdata', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "Records": data,"CarrierCode":carrierCode,"ClientId":clientId})
                })
                    .then(response => {
                        if (response.ok) {
                            return response.blob();
                        } else {
                            return response.text().then(errorMessage => {
                                throw new Error(errorMessage);
                            });
                        }
                    })
                    .then(blob => {
                        // Create a temporary URL for the blob
                        const url = URL.createObjectURL(blob);

                        // Create a link element to trigger the download
                        const link = document.createElement('a');
                        link.href = url;



                        var now = new Date();
                        var formattedDate = ("0" + now.getDate()).slice(-2);
                        var formattedMonth = ("0" + (now.getMonth() + 1)).slice(-2);
                        var formattedYear = now.getFullYear().toString().slice(-2);
                        var formattedHours = ("0" + now.getHours()).slice(-2);
                        var formattedMinutes = ("0" + now.getMinutes()).slice(-2);
                        var formattedSeconds = ("0" + now.getSeconds()).slice(-2);

                        var fileName = "manifest_" + formattedDate + formattedMonth + formattedYear + formattedHours + formattedMinutes + formattedSeconds + ".csv";





                        link.download = fileName;

                        // Programmatically click the link to start the download
                        link.click();

                        // Clean up the temporary URL
                        URL.revokeObjectURL(url);
                    })
                    .catch(error => {
                        console.error('Error downloading CSV:', error);
                    });
            };

    render() {
        return (
            <Button color="primary" onClick={this.handleClick}>Download Manifest</Button>
        );
    }
}