import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row, Container, Label, Input, Form, FormGroup, Spinner } from 'reactstrap';
import moment from 'moment';
import { PackageTable } from 'components/PackageTable';
import _ from "lodash";

export class SubmissionResponseModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isloading: true,
            consignmentId: 0,
            code: 0,
            message: ""
        };
    }

    componentDidMount = () => {
        var response = this.props.response;
        var consignmentId = 0;
        var code = 0;
        var message = "";

        try {
            console.log(response);

            if (response.message) {
                var json = JSON.parse(response.message)
                consignmentId = json.carrierConsignmentId;
                code = response.code;
                if (json.errorMessages && json.errorMessages.length > 0)
                    message = json.errorMessages[0].errorMessage;
                else
                    message = "Consignment submission complete";
            }

        } catch (err) {
            code = 400;
            message = "Error submitting consignment"
            console.log(err);
        }

        this.setState({
            isloading: false,
            consignmentId,
            message,
            code
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Submission Response</ModalHeader>
                <ModalBody>
                    <Container>
                        {this.state.isloading ? <> </>
                            : <Row>
                                <Label>{`Response Status: ${this.state.code}`}</Label>
                                {this.state.code == 200 ?
                                    <Label>{`Consignment Number: ${this.state.consignmentId}`}</Label>
                                    :
                                    <Label>{`Error Message: ${this.state.message}`}</Label>
                                }</Row>
                        }
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.props.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal >
        )
    }
}
