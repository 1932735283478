import { SessionContext } from 'Session';
import { DeleteUserConfirmation } from 'components/modals/DeleteUserConfirmation';
import { UserDetailsModal } from 'components/modals/UserDetailsModal';
import UsersTable from 'components/tables/UsersTable';
import React, { Component } from 'react';
import { UncontrolledTooltip, InputGroup, Card, CardBody, CardTitle, CardSubtitle, CardText, CardHeader, ButtonGroup, Input, Button, Spinner, CardImg, Row, Col, Badge, Form, Label, Table } from 'reactstrap';


class AdminHome extends Component {

    static contextType = SessionContext;

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            carriers: [],
            loading: false,
            userDetailsModalVisible: false,
            userModalError: null,
            user: null,
            rSelected: 1,
            deleteUserModalVisible: false,
            clientId: null
        }
    }

    componentDidMount() {
        const { clientId } = this.context;

        this.setState({
            clientId: clientId
        });

        this.getUserList();
    }

    componentDidUpdate = () => {
        const { clientId } = this.context;

        if (clientId !== this.state.clientId) {
            this.setState({
                clientId: clientId
            });
            this.getUserList();
        }
    }

    getUserList = async () => {
        const { clientId } = this.context;

        try {
            this.setState({ loading: true });
            this.setState({ users: [] });

            const response = await fetch("users?clientId=" + clientId);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            this.setState({ users: data });
            this.setState({ loading: false });
        } catch (error) {
            console.error('Error fetching users:', error);
            this.setState({ suggestions: [] }); // Clear suggestions in case of an error
            this.setState({ suggestionsToDisplay: [] });
            this.setState({ loading: false });
        };
    }


    saveUser = async (user) => {
        try {
            this.setState({ loading: true });
            this.setState({ users: [] });
            const response = await fetch("users",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(user)
                });
            if (!response.ok) {
                const resp = await response.json();
                this.setState({ userModalError: 'Error saving user: ' + resp.message });
            } else {
                this.getUserList();
                this.toggleUserDetailsModal();
            }
            this.setState({ loading: false });
        } catch (error) {
            console.error('Error saving user:', error);
            this.setState({ userModalError: 'Error saving user:' + error });
            this.setState({ loading: false });
        };
    }


    deleteUser = async (user) => {
        try {
            this.setState({ loading: true });
            this.setState({ users: [] });

            const response = await fetch(`users?oid=${user.oid}&email=${user.email}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(user)
                });
            if (!response.ok) {
                this.setState({ userModalError: 'Error deleting user:' + response.body })
            } else {
                this.getUserList();
                this.toggleUserDetailsModal();
            }
            this.setState({ loading: false });
        } catch (error) {
            console.error('Error deleting user:', error);
            this.setState({ userModalError: 'Error deleting user:' + error })
            this.setState({ loading: false });
        };
    }

    toggleUserDetailsModal = () => {
        this.setState({
            userDetailsModalVisible: !this.state.userDetailsModalVisible
        })
    }

    toggleDeleteUserModal = () => {
        this.setState({
            deleteUserModalVisible: !this.state.deleteUserModalVisible
        })
    }

    addNewUser = () => {
        this.setState({
            userDetailsModalVisible: true,
            user: {}
        })
    }

    editUserInfo = (user) => {
        this.setState({
            userDetailsModalVisible: true,
            user: user
        })
    }

    deleteUserClicked = (user) => {
        this.setState({
            deleteUserModalVisible: true,
            user: user
        })
    }

    setRSelected = (id) => {
        this.setState({
            rSelected: id
        })
    }

    render() {
        return (
            <div>
                <Row md="4" style={{ marginTop: '10px' }}>
                    <ButtonGroup>
                        <Button onClick={() => this.addNewUser()} id="refresh" color="success">
                            <i className={'fa-solid fa-plus'} /> Add User
                        </Button>
                        <UncontrolledTooltip placement="bottom" target="refresh">
                            Add new user
                        </UncontrolledTooltip>
                    </ButtonGroup>
                </Row>
                <div style={{ paddingTop: '30px' }}>
                    <UsersTable
                        users={this.state.users}
                        editUserClicked={this.editUserInfo}
                        deleteUserClicked={this.deleteUserClicked}
                    />
                    <div ref={this.lastElementRef} style={{ padding: '10px', textAlign: 'center' }}>
                        {this.state.loading ?
                            <Spinner>
                                Loading...
                            </Spinner>
                            :
                            <div />
                        }
                    </div>
                </div>
                {this.state.userDetailsModalVisible &&
                    <UserDetailsModal
                        isOpen={this.state.userDetailsModalVisible}
                        toggle={this.toggleUserDetailsModal}
                        user={this.state.user}
                        saveUser={this.saveUser}
                        error={this.state.userModalError}
                    />
                }
                {this.state.deleteUserModalVisible &&
                    <DeleteUserConfirmation
                        isOpen={this.state.deleteUserModalVisible}
                        toggle={this.toggleDeleteUserModal}
                        user={this.state.user}
                        deleteConfirm={this.deleteUser}
                    />
                }
            </div>
        );
    }
};

export default AdminHome;
