export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `_configuration`,
    // ApiAuthorizationPrefix: prefix,
    // Login: `${prefix}/${LoginActions.Login}`,
    // LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    // LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    // Register: `${prefix}/${LoginActions.Register}`,
    // Profile: `${prefix}/${LoginActions.Profile}`,
    // LogOut: `${prefix}/${LogoutActions.Logout}`,
    // LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    // LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    // IdentityRegisterPath: 'Identity/Account/Register',
    // IdentityManagePath: 'Identity/Account/Manage'
  };
  