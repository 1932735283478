import React, { useEffect, useState, useReducer, createContext, useContext } from "react";
import { AuthProvider, useAuth, hasAuthParams } from "react-oidc-context";
import { useSearchParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { ApplicationPaths } from "Contants";

const SessionContext = createContext();


const SessionProvider = ({ children }) => {
        const auth = useAuth();
        const [searchParams, setSearchParams] = useSearchParams();
        const [clientId, setClientId] = useState();
        const [settings, setSettings] = useState();
        const [error, setError] = useState();


        const login = async () => {
            void auth.signinRedirect();
        }

        const logout = async () => {
            void auth.signoutRedirect();
        }

        const _setClientId = async (clientId) =>
        {
            setClientId(clientId);
        }


        const readUserSettings = async () => {
            const cipherText = localStorage.getItem('userData');
            let settings = {};

            try {
                if (!cipherText) {
                    var response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl + "/settings/" + auth.user.profile.oid + "/" + auth.user.profile.unique_name);

                    let responsejson = await response.json();                    
                    if (!response.ok) {
                        setError(responsejson)
                    }
                    else {
                        storeUserSettings(responsejson.settings, "KC2RzVEW");
                        settings = JSON.parse(responsejson.settings);
                    }
                } else {
                    const cipherText = localStorage.getItem('userData');
                    const bytes = CryptoJS.AES.decrypt(cipherText, "KC2RzVEW");
                    const originalText = bytes.toString(CryptoJS.enc.Utf8);

                    settings = JSON.parse(originalText);
                    if (settings.configuration)
                        settings.configuration = JSON.parse(settings.configuration);
                }

                //remove query params after login as errors on page refresh
                searchParams.delete('code');
                searchParams.delete('state');
                searchParams.delete('session_state');
                setSearchParams(searchParams);

                setSettings(settings);
                setClientId(settings.clientId);
            }
            catch(err)
            {
                console.log(err)
                setError(err)
            }
        };

        const storeUserSettings = (input, secretKey) => {
            const cipherText = CryptoJS.AES.encrypt(input, secretKey).toString();
            localStorage.setItem('userData', cipherText);
        };

        useEffect(() => {
            if (!hasAuthParams() &&
                !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
                auth.signinRedirect();
            }
            if (auth.isAuthenticated && !auth.isLoading)
                readUserSettings();
        }, [auth]);

        return (
            <SessionContext.Provider value={{ clientId,_setClientId, settings, login, logout, auth, error }}>
                { children }
            </SessionContext.Provider>
        );
    };

export { SessionContext, SessionProvider };


