import React, { Component } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import { Spinner } from 'reactstrap';



export class DocumentViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numPages: 0
        }
    }



    onDocumentLoadSuccess = (pdf) => {
        this.props.numberOfPagesChange(pdf.numPages)
    }

    onDocumentLoadError = (error) => {
        console.error(error)
    }

    


    render() {
        return (
            <div>
                {this.props.data ?
                    <Document file={this.props.data} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={this.onDocumentLoadError}>
                        <Page renderTextLayer={false} rotate={this.props.rotationAngle} renderAnnotationLayer={false} pageNumber={this.props.page} />
                    </Document>
                    :
                    <Spinner />
                }
            </div>
        );
    }
}