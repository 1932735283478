import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row, Container, Label, Input, Form, FormGroup, Spinner } from 'reactstrap';
import moment from 'moment';
import { PackageTable } from 'components/PackageTable';
import _ from "lodash";

export class ViewRequestModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isloading: true,
            editMode: false,
            isSubmitting: false,
            isSaving: false,
            file: null
        };
    }

    componentDidMount = () => {
        this.setState({
            isloading: false,
            file: this.props.file
        });
    }

    toggleEditMode = () => {
        this.setState({
            editMode: !this.state.editMode
        })

        if (this.state.editMode) {
            this.saveShipment();
        }
    }

    editTextValue = (e) => {
        const { name, value } = e.target;

        let nextState = _.cloneDeep(this.state.file); // clone the state to not mutate it directly
        _.set(nextState, name, value);

        this.setState({
            file: nextState
        })
    }

    saveShipment = async () => {
        this.setState({
            isSaving: true
        })

        const requestBody = JSON.stringify(this.state.file.requestShipment);
        const response = await fetch(`request?id=${this.state.file.id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody
        });

        this.setState({
            isSaving: false
        })
    }

    resubmitShipment = async () => {
        this.setState({
            isSubmitting: true
        })

        const requestBody = JSON.stringify(this.state.file.requestShipment);
        const response = await fetch(`request?id=${this.state.file.id}&host=${this.state.file.host}&apiKey=${this.state.file.apiKey}&apiSecret=${this.state.file.apiSecret}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody
        });

        const data = await response.json();
        this.props.displaySubmissionResponse(data);

        this.setState({
            isSubmitting: false
        })
    }

    render() {
        let request, response, shipper, receiver, carrier;
        const { settings } = this.props;
        let normalizedAddress = false;
        if (settings.configuration)
            normalizedAddress = settings.configuration[0].Enabled;

        if (this.state.file) {
            request = this.state.file.requestShipment;
            response = this.state.file.responseShipment;
            shipper = request.shipper;
            receiver = request.receiver;
            carrier = request.carrier;
        }

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} style={{ maxWidth: '80%' }}>
                <ModalHeader toggle={this.props.toggle}> Request Details: {!request ? "" : request.sourceShipmentId}</ModalHeader>
                <ModalBody>
                    <Container>
                        {this.state.isloading ? <div />
                            :
                            <>
                                <Row>
                                    <Col md="4">
                                        <Form>
                                            <FormGroup>
                                                <h5 className="card-title-2">Collection Information</h5>
                                                {normalizedAddress ?
                                                    <>
                                                        <Label>Company Name</Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.companyName"
                                                            value={shipper.address.companyName}
                                                        />
                                                        <Label>Address Line 1</ Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.addressLine1"
                                                            value={shipper.address.addressLine1}
                                                        />
                                                        <Label>Address Line 2</Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.addressLine2"
                                                            value={shipper.address.addressLine2}
                                                        />
                                                        <Label>Address Line 3</Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.addressLine3"
                                                            value={shipper.address.addressLine3}
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        <Label>Building Number</Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.buildingNumber"
                                                            value={shipper.address.buildingNumber}
                                                        />
                                                        <Label>Building Name</ Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.buildingName"
                                                            value={shipper.address.buildingName}
                                                        />
                                                        <Label>Company Name</Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.companyName"
                                                            value={shipper.address.companyName}
                                                        />
                                                        <Label>Street Name</Label>
                                                        <Input
                                                            readOnly={!this.state.editMode}
                                                            onChange={this.editTextValue}
                                                            name="requestShipment.shipper.address.streetName"
                                                            value={shipper.address.streetName}
                                                        />
                                                    </>
                                                }
                                                <Label>City</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.shipper.address.city"
                                                    value={shipper.address.city}
                                                />
                                                <Label>Post Code</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.shipper.address.postCode"
                                                    value={shipper.address.postCode}
                                                />
                                                <Label>Country</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.shipper.address.country"
                                                    value={shipper.address.country}
                                                />
                                                <h5 className="card-title-2">Collection Contact Information</h5>
                                                <Label>Name</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.shipper.contact.fullName"
                                                    value={shipper.contact.fullName}
                                                />
                                                <Label>Telephone</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.shipper.contact.telephone"
                                                    value={shipper.contact.telephone}
                                                />
                                                <Label>Email</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.shipper.contact.email"
                                                    value={shipper.contact.email}
                                                />
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col md="4">
                                        <h5 className="card-title-2">Delivery Information</h5>
                                        {normalizedAddress ?
                                            <>
                                                <Label>Company Name</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.companyName"
                                                    value={receiver.address.companyName}
                                                />
                                                <Label>Address Line 1</ Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.addressLine1"
                                                    value={receiver.address.addressLine1}
                                                />
                                                <Label>Address Line 2</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.addressLine2"
                                                    value={receiver.address.addressLine2}
                                                />
                                                <Label>Address Line 3</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.addressLine3"
                                                    value={receiver.address.addressLine3}
                                                />
                                            </>
                                            :
                                            <>
                                                <Label>Building Number</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.buildingNumber"
                                                    value={receiver.address.buildingNumber}
                                                />
                                                <Label>Building Name</ Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.buildingName"
                                                    value={receiver.address.buildingName}
                                                />
                                                <Label>Company Name</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.companyName"
                                                    value={receiver.address.companyName}
                                                />
                                                <Label>Street Name</Label>
                                                <Input
                                                    readOnly={!this.state.editMode}
                                                    onChange={this.editTextValue}
                                                    name="requestShipment.receiver.address.streetName"
                                                    value={receiver.address.streetName}
                                                />
                                            </>
                                        }
                                        <Label>City</Label>
                                        <Input
                                            readOnly={!this.state.editMode}
                                            onChange={this.editTextValue}
                                            name="requestShipment.receiver.address.city"
                                            value={receiver.address.city}
                                        />
                                        <Label>Post Code</Label>
                                        <Input
                                            readOnly={!this.state.editMode}
                                            onChange={this.editTextValue}
                                            name="requestShipment.receiver.address.postCode"
                                            value={receiver.address.postCode}
                                        />
                                        <Label>Country</Label>
                                        <Input
                                            readOnly={!this.state.editMode}
                                            onChange={this.editTextValue}
                                            name="requestShipment.receiver.address.country"
                                            value={receiver.address.country}
                                        />
                                        <h5 className="card-title-2">Delivery Contact Information</h5>
                                        <Label>Name</Label>
                                        <Input
                                            readOnly={!this.state.editMode}
                                            onChange={this.editTextValue}
                                            name="requestShipment.receiver.contact.fullName"
                                            value={receiver.contact.fullName}
                                        />
                                        <Label>Telephone</Label>
                                        <Input
                                            readOnly={!this.state.editMode}
                                            onChange={this.editTextValue}
                                            name="requestShipment.receiver.contact.telephone"
                                            value={receiver.contact.telephone}
                                        />
                                        <Label>Email</Label>
                                        <Input
                                            readOnly={!this.state.editMode}
                                            onChange={this.editTextValue}
                                            name="requestShipment.receiver.contact.email"
                                            value={receiver.contact.email}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <h5 className="card-title-2">Shipment Information</h5>
                                        <Label>Shipment ID</Label>
                                        <Input readOnly value={request.sourceShipmentId} />
                                        <Label>Consignment Number</Label>
                                        <Input readOnly value={response.carrierConsignmentId || request.consignmentNumber} />
                                        <Label>Consignment Date</Label>
                                        <Input readOnly value={moment(request.collectionDate).format("DD/MM/YYYY")} />
                                        <Label>Carrier</Label>
                                        <Input readOnly value={carrier.carrierCode} />
                                        <Label>Service Code</Label>
                                        <Input readOnly value={carrier.serviceGroup} />
                                        <Label>Status</Label>
                                        <Input readOnly value={this.props.file.requestStatus.toUpperCase()} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.props.file.requestResult == 400 && response.errorMessages != null && response.errorMessages.length > 0 &&
                                            <div style={{ marginTop: '30px' }}>
                                                <p className="card-title-3">Error Messages</p>
                                                <p className="card-category-2">{'Message: ' + response && response.errorMessages && response.errorMessages.length > 0 ?response.errorMessages[0].errorMessage: "Consignment creation failed."}</p>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '40px' }}>
                                    <h5 className="card-title-2">Package Details</h5>
                                    <PackageTable
                                        items={request.shipmentPackages}
                                    />
                                </Row>
                            </>}
                    </Container>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={this.props.toggle}>
                        Re-Print Label
                    </Button>
                    <Button color="primary" onClick={this.props.toggle}>
                        Track Shipment
                    </Button> */}
                    {(this.props.file.requestResult == 400 || this.props.file.requestResult == 202) &&
                        <>
                            <Button color="success" onClick={this.toggleEditMode}>
                                {this.state.isSaving ?
                                    <Spinner size="sm" /> :
                                    this.state.editMode ?
                                        "Save" : "Edit"
                                }
                            </Button>
                            <Button color="primary" disabled={this.state.isSubmitting} onClick={this.resubmitShipment}>
                                {this.state.isSubmitting ?
                                    <Spinner size="sm" /> : "submit"}
                            </Button>
                        </>
                    }
                    
                    <Button color="secondary" onClick={this.props.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal >
        )
    }
}
