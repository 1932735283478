import React, { Component } from 'react';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Table, Pagination, PaginationItem, PaginationLink, Spinner, UncontrolledDropdown, DropdownToggle, Button, ButtonGroup } from 'reactstrap';
import moment from 'moment';

export class PackageTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCollapse: false,
            currentPage: 0,
        };
    }

    render() {
        return (
            <Table
                hover
                style={{padding: '50px'}}
            >
                <thead>
                    <tr>
                        <th>Package ID</th>
                        <th>Product Code</th>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Weight</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.items.map(pack => (

                        pack.shipmentItems.map(item =>
                        (
                            <tr>
                                <td>{pack.packageRef}</td>
                                <td>{item.productCode}</td>
                                <td>{item.description}</td>
                                <td>{item.quantity.value}</td>
                                <td>{item.netItemWeight.value}</td>
                            </tr>                            
                        ))
                    ))}
                </tbody>
            </Table>
        )
    }
}