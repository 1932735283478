import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row, Container, Label } from 'reactstrap';

export class DeleteUserConfirmation extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} style={{ maxWidth: '40%' }}>
                <ModalHeader toggle={this.props.toggle}>{'User: ' + this.props.user.name}</ModalHeader>
                <ModalBody>
                    <Label>Are you sure you wish to delete this user?</Label>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => this.props.deleteConfirm()}>
                        Yes
                    </Button>
                    <Button color="secondary" onClick={this.props.toggle}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}