import React, { Component, useContext } from 'react';
import { UncontrolledTooltip, FormFeedback, Input, Form, FormGroup, Label, Button, ButtonGroup, Card, CardBody, CardFooter, CardTitle, Col, Row, Collapse, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { ProcessChartCard } from './ProcessCardChart';
import { EventsTable } from './EventsTable';
import moment from 'moment';
import queryString from 'stringquery';
import { ViewRequestModal } from './modals/ViewRequestModal';
import { ViewLabelModal } from './modals/ViewLabelModal';
import { SubmissionResponseModal } from './modals/SubmissionResponseModal';
import { ErrorMessageModal } from './modals/ErrorMessageModal';
import { RequestInfoCard } from './RequestInfoCards';
import { SessionContext } from '../Session';


const chartConfig = {
    labels: [
        "Mon",
        "Tue",
        "Wed",
        "Thur",
        "Fri",
        "Sat",
        "Sun"
    ],
    datasets: [
        {
            data: [0, 19, 15, 20, 30, 40, 40],
            fill: false,
            borderColor: "#6bd098",
            backgroundColor: "transparent",
            pointBorderColor: "#6bd098",
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            tension: 0.4,
            label: "Success"
        },
        {
            data: [0, 5, 10, 5, 1, 2, 0, 3],
            fill: false,
            borderColor: "#f17e5d",
            backgroundColor: "transparent",
            pointBorderColor: "#f17e5d",
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 8,
            tension: 0.4,
            label: "Failure"
        },
    ],
};

const chartOptions = {
    plugins: {
        legend: {
            display: false,
            enabled: false
        },
    },
    scales: {
        y: {
            ticks: {
                stepSize: 1
            }
        }
    }
};

class Home extends Component {
    static displayName = Home.name;

    static contextType = SessionContext;

    constructor(props) {
        super(props);

        this.state = {
            clientId:'',
            collapse: false,
            data: [],
            documents: [],
            pageSize: 10,
            loading: true,
            pageCount: 0,
            rSelected: 1,
            filterText: 'This Week',
            sucessCount: 0,
            failedCount: 0,
            pendingCount: 0,
            parsed: queryString(window.location.search),
            carrier: 'ALL',
            dropDownOpen: false,
            dropDownValue: '',
            toDate: '',
            fromDate: '',
            toDateInvalid: false,
            fromDateInvalid: false,
            filterChanged: false,
            viewRequestModalOpen: false,
            submissionResponseModalOpen: false,
            submissionResponse: null,
            selectedRequest: null,
            displayLabelModalOpen: false,
            displayErrorModal: true,
            parsed: queryString(window.location.search),
            requestStatus: 'ALL',
            requestStatusDropDownOpen: false
        };
    }



    componentDidMount()
    {
        const {clientId } = this.context;

        if (clientId)
        {
            this.setState({ clientId: clientId });
            const fromDate = moment().format("yyyy-MM-DD");
            const toDate = moment().format("yyyy-MM-DD 23:59:59");
            this.populateData(fromDate, toDate, 'ALL', 'ALL', 24, 'hA');
        }
    }

    componentDidUpdate(prevPros)
    {
        const { clientId } = this.context;
        if (clientId && this.state.clientId != clientId)
        { 
            this.setState({ clientId: clientId, rSelected:1 });
            const fromDate = moment().format("yyyy-MM-DD");
            const toDate = moment().format("yyyy-MM-DD 23:59:59");
            this.populateData(fromDate, toDate, 'ALL', 'ALL', 24, 'hA');
        }
    }

    viewRequestModalToggle = (file) => {
        this.setState({
            viewRequestModalOpen: !this.state.viewRequestModalOpen,
            selectedRequest: file
        });
    }

    submissionResponseModalToggle = () => {
        this.setState({
            submissionResponseModalOpen: !this.state.submissionResponseModalOpen
        });

        if (this.state.submissionResponseModalOpen)
            this.setRSelected(this.state.rSelected)
    }

    viewLabelModalToggle = (file) => {
        this.setState({
            displayLabelModalOpen: !this.state.displayLabelModalOpen,
            selectedRequest: file
        });
    }

    errorModalToggle = () => {
        this.setState({
            displayErrorModal: !this.state.displayErrorModal
        });
    }

    displaySubmissionResponse = (response) => {
        this.setState({
            submissionResponseModalOpen: true,
            viewRequestModalOpen: false,
            submissionResponse: response
        })
    }

    populateData = async (fromDate, toDate, carrierCode, status, rowCount, displayType) => {
        this.setState({ loading: true });

        const { clientId } = this.context;

        if (!carrierCode)
            carrierCode = "ALL";

        if (!status)
            status = "ALL";

        const response = await fetch(`request?clientId=${clientId}&fromDateTime=${fromDate}&toDateTime=${toDate}&carrierCode=${carrierCode}&status=${status}&deliveryCountry=ALL`); //
        const data = await response.json();
        var rows;
        if (rowCount === 24) {
            rows = this.filterData(data, fromDate, rowCount, displayType, 'hour');
        }
        else {
            rows = this.filterData(data, fromDate, rowCount, displayType, 'day');
        }

        const noPages = Math.ceil(rows.length / 10);

        this.setState({ data: data, documents: rows.sort((a, b) => moment(b.requestDate) - moment(a.requestDate)), loading: false, pageCount: noPages });
    }

    filterData = (data, startdate, rowCount, displayType, timeType) => {
        var sucesscount = [];
        var failedcount = [];
        var pendingcount = [];
        var labels = [];
        var tableRows = [];
        const day = moment(startdate);

        for (var i = 0; i < rowCount; i++) {
            const items = data.filter(row => moment(row.requestDate).isSame(day, timeType));
            sucesscount.push(items.filter(row => row.requestResult == 200).length);
            failedcount.push(items.filter(row => row.requestResult != 200).length);
            pendingcount.push(items.filter(row => row.requestStatus == "Pending").length);
            labels.push(day.format(displayType));
            tableRows = tableRows.concat(items);
            day.add(1, timeType);
        }

        chartConfig.datasets[0].data = sucesscount;
        chartConfig.datasets[1].data = failedcount;
        chartConfig.labels = labels;

        this.setState({
            sucessCount: sucesscount.reduce((r, c) => r + c, 0),
            failedCount: failedcount.reduce((r, c) => r + c, 0),
            pendingCount: pendingcount.reduce((r, c) => r + c, 0)
        });

        return tableRows;
    }

    applyFilter = async () => {
        var filterText = '';

        let toDate;
        if (!this.state.toDate) {
            toDate = moment().format("yyyy-MM-DD 23:59:59");
        }
        else {
            toDate = moment(this.state.toDate).format("yyyy-MM-DD 23:59:59");
        }

        let fromDate;
        if (!this.state.fromDate) {
            fromDate = moment().format("yyyy-MM-DD");
        }
        else {
            fromDate = moment(this.state.fromDate).format("yyyy-MM-DD");
        }

        filterText = "Custom Filter's";


        this.setState({
            rSelected: -1,
            filterText: filterText,
            filterChanged: false,
            collapse: false
        });

        if ((Math.abs(moment(fromDate).diff(moment(toDate), 'days')) + 1) === 1) {
            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, 24, 'hA');

        }
        else if ((Math.abs(moment(fromDate).diff(moment(toDate), 'days')) + 1) < 8) {
            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, 7, 'ddd');

        }
        else {
            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, (Math.abs(moment(fromDate).diff(moment(toDate), 'days')) + 1), 'DD');
        }
    }

    clearFilter = async () => {
        var filterText = '';

        //set the state of all the controls to their defaults


        //run the 'today query'
        const fromDate = moment().format("yyyy-MM-DD");
        const toDate = moment().format("yyyy-MM-DD 23:59:59");
        await this.populateData(fromDate, toDate, 'ALL', 'ALL', 24, 'hA');
        filterText = "Today's";

        this.setState({
            carrier: "ALL",
            fromDate: "",
            toDate: "",
            rSelected: 1,
            filterText: filterText,
            filterChanged: false,
            toDateInvalid: false,
            fromDateInvalid: false,
            requestStatus: "ALL"
        });
    }

    onRadioBtnClick = async (id) => {
        this.setState({ requestStatus: id });
    }

    setRSelected = async (id) => {
        var filterText = '';

        let fromDate;
        let toDate;
        if (id == 1) {
            fromDate = moment().format("yyyy-MM-DD");
            toDate = moment().format("yyyy-MM-DD 23:59:59");
            filterText = "Today's";
            this.setState({
                rSelected: id,
                filterText: filterText,
                fromDate: fromDate,
                toDate: moment(toDate).format("yyyy-MM-DD"),
                filterChanged: false,
                toDateInvalid: false,
                fromDateInvalid: false
            });

            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, 24, 'hA');


        }
        else if (id == 2) {
            fromDate = moment().subtract('1', 'day').format("yyyy-MM-DD");
            toDate = moment().subtract('1', 'day').format("yyyy-MM-DD 23:59:59");
            filterText = "Yesterday's";
            this.setState({
                rSelected: id,
                filterText: filterText,
                fromDate: fromDate,
                toDate: moment(toDate).format("yyyy-MM-DD"),
                filterChanged: false,
                toDateInvalid: false,
                fromDateInvalid: false
            });

            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, 24, 'hA');

        }
        else if (id == 3) {
            fromDate = moment().startOf('week').format("yyyy-MM-DD");
            toDate = moment().endOf('week').format("yyyy-MM-DD 23:59:59");
            filterText = "This Week's";
            this.setState({
                rSelected: id,
                filterText: filterText,
                fromDate: fromDate,
                toDate: moment(toDate).format("yyyy-MM-DD"),
                filterChanged: false,
                toDateInvalid: false,
                fromDateInvalid: false
            });

            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, 7, 'ddd');

        }
        else if (id == 4) {
            fromDate = moment().subtract('1', 'week').startOf('week').format("yyyy-MM-DD");
            toDate = moment().subtract('1', 'week').endOf('week').format("yyyy-MM-DD 23:59:59");
            filterText = "Last Week's";
            this.setState({
                rSelected: id,
                filterText: filterText,
                fromDate: fromDate,
                toDate: moment(toDate).format("yyyy-MM-DD"),
                filterChanged: false,
                toDateInvalid: false,
                fromDateInvalid: false
            });

            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, 7, 'ddd');

        }
        else if (id == 5) {
            fromDate = moment().startOf('month').format("yyyy-MM-DD");
            toDate = moment().endOf('month').format("yyyy-MM-DD 23:59:59");

            filterText = "This Month's";
            this.setState({
                rSelected: id,
                filterText: filterText,
                fromDate: fromDate,
                toDate: moment(toDate).format("yyyy-MM-DD"),
                filterChanged: false,
                toDateInvalid: false,
                fromDateInvalid: false
            });

            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, moment().startOf('month').daysInMonth(), 'DD');

        }
        else if (id == 6) {
            fromDate = moment().subtract('1', 'month').startOf('month').format("yyyy-MM-DD");
            toDate = moment().subtract('1', 'month').endOf('month').format("yyyy-MM-DD");
            filterText = "Last Month's";
            this.setState({
                rSelected: id,
                filterText: filterText,
                fromDate: fromDate,
                toDate: moment(toDate).format("yyyy-MM-DD"),
                filterChanged: false,
                toDateInvalid: false,
                fromDateInvalid: false
            });

            await this.populateData(fromDate, toDate, this.state.carrier, this.state.requestStatus, moment().subtract('1', 'month').daysInMonth(), 'DD');

        }
    }

    toggle = () => {
        this.setState({
            collapse: !this.state.collapse
        })
    }


    toggleCarrier = () => {
        this.setState({
            dropDownOpen: !this.state.dropDownOpen,
        })
    }

    toggleRequestStatus = () => {
        this.setState({
            requestStatusDropDownOpen: !this.state.requestStatusDropDownOpen,
        })
    }

    handleRequestStatusChange = (status) => {
        if (status && status.target && status.target.textContent) {
            if (this.state.requestStatus !== status.target.textContent) {
                this.setState({ requestStatus: status.target.textContent });
                this.setState({ filterChanged: true })
            }
        }
    }

    handleCarrierChange = (code) => {
        if (code && code.target && code.target.textContent) {
            if (this.state.carrier !== code.target.textContent) {
                this.setState({ carrier: code.target.textContent });
                this.setState({ filterChanged: true })
            }
        }
    }


    handleFromDateChange = (e) => {
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');

        if (this.state.fromDate !== newDate) {
            this.setState({ fromDate: newDate });
            if (this.validateFromDateFilterClause(newDate)) {
                this.setState({ fromDateInvalid: false });
                this.setState({ filterChanged: true });
            }
            else {
                this.setState({ filterChanged: false });
            }
        }
    }

    handleToDateChange = (e) => {
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');

        if (this.state.toDate !== newDate) {
            this.setState({ toDate: newDate });
            if (this.validateToDateFilterClause(newDate)) {
                this.setState({ filterChanged: true });
                this.setState({ toDateInvalid: false });
            }

            else {

                this.setState({ filterChanged: false });
            }
        }
    }

    validateToDateFilterClause = (date) => {
        if ((!this.state.fromDate || this.state.fromDate === 'Invalid date') && date) {
            //need to set a from date
            this.setState({ fromDateInvalid: true });
            return false;
        }
        else if (!date && this.state.fromDate) {
            this.setState({ toDateInvalid: true });
            return false;
        }
        else if (moment(this.state.fromDate) > moment(date)) {
            this.setState({ toDateInvalid: true });
            return false;
        }
        else {
            return true;
        }
    }

    validateFromDateFilterClause = (date) => {
        if (!date && this.state.toDate) {
            this.setState({ toDateInvalid: true });
            //need to set a to date
            return false;
        }
        else if (!this.state.toDate && date) {
            this.setState({ toDateInvalid: true });
            return false;
        }
        else if (moment(date) > moment(this.state.toDate)) {
            this.setState({ fromDateInvalid: true });
            return false;
        }
        else {
            return true;
        }
    }


    render() {
       
        const { auth, login, error, settings } = this.context;

        var message = "Please login to continue";

        switch (auth.activeNavigator) {
            case "signinSilent":
                message = "Signing you in...";
            case "signoutRedirect":
                message = "Signing you out...";
        }

        if (auth.isLoading) {
            message = "Loading...";
        }

        if (auth.error) {
            message = `Error: ${auth.error.message}`;
        }

        return (
            <div className="content">
                {!auth.isAuthenticated ?
                    <Col>
                        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                            <p className="h5">{message}</p>
                        </Row>
                        <Label for="clientTextInput">
                            Client ID:
                        </Label>
                        <Input id="clientTextInput" />
                        <Row>
                            <Col>
                                <Button id="login" color="primary" onClick={() => login()} style={{ marginBottom: '1rem' }}>
                                    Login
                                </Button>
                                <UncontrolledTooltip placement="right" target="login">
                                    Click to login.
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                    </Col>
                    :
                    <div>
                        <Col md='5'>
                            <Button id="filter" color="primary" onClick={() => this.toggle()} style={{ marginBottom: '1rem', marginRight: '10px' }}>
                                <i className={this.state.collapse ? 'fa-solid fa-square-caret-up' : 'fa-solid fa-square-caret-down'} /> Filter
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="filter">
                                Click to show/hide filter criteria.
                            </UncontrolledTooltip>
                            <Button id="refresh" color="info" onClick={() => this.setRSelected(this.state.rSelected)} style={{ marginBottom: '1rem' }}>
                                <i className={'fa-solid fa-refresh'} /> Refresh
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="refresh">
                                Refresh dashboard data
                            </UncontrolledTooltip>
                        </Col>

                        <Row style={{ marginBottom: '1rem' }}>
                            <Collapse isOpen={this.state.collapse}>
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <FormGroup row>
                                                <Col sm={10}>
                                                    <Label for="carrierDropDown">
                                                        Carrier Code
                                                    </Label>

                                                    <Dropdown id="carrierDropDown" isOpen={this.state.dropDownOpen} toggle={this.toggleCarrier}>
                                                        <DropdownToggle caret>
                                                            {this.state.carrier}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {(settings !== null && settings !== undefined) && (settings.carriers !== null && settings.carriers !== undefined) ? (
                                                                settings.carriers.split(',').map((item, index) => (<DropdownItem key={item} onClick={this.handleCarrierChange}>{item}</DropdownItem>))
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    <UncontrolledTooltip placement="left-end" target="carrierDropDown">
                                                        Filter by carrier code.
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col sm={10}>
                                                    <Label for="requestStatus">
                                                        Request Status
                                                    </Label>
                                                    <Dropdown id="requestStatusDropDown" isOpen={this.state.requestStatusDropDownOpen} toggle={this.toggleRequestStatus}>
                                                        <DropdownToggle caret>
                                                            {this.state.requestStatus}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={this.handleRequestStatusChange}>ALL</DropdownItem>
                                                            <DropdownItem onClick={this.handleRequestStatusChange}>SUCCESS</DropdownItem>
                                                            <DropdownItem onClick={this.handleRequestStatusChange}>ERROR</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    <UncontrolledTooltip placement="left-end" target="requestStatusDropDown">
                                                        Filter by request status.
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col sm={10}>
                                                    <Label for="fromDate">
                                                        From Date
                                                    </Label>
                                                    <Input
                                                        id="fromDate"
                                                        name="fromDate"
                                                        onChange={this.handleFromDateChange}
                                                        placeholder="date placeholder"
                                                        value={this.state.fromDate}
                                                        type="date"
                                                        invalid={!!this.state.fromDateInvalid} />
                                                    <UncontrolledTooltip placement="right" target="fromDate">
                                                        Filter by from date.
                                                    </UncontrolledTooltip>
                                                    <Label for="toDate">
                                                        To Date
                                                    </Label>
                                                    <Input
                                                        id="toDate"
                                                        name="toDate"
                                                        onChange={this.handleToDateChange}
                                                        placeholder="date placeholder"
                                                        value={this.state.toDate}
                                                        type="date"
                                                        invalid={!!this.state.toDateInvalid} />
                                                    <UncontrolledTooltip placement="right" target="toDate">
                                                        Filter by to date.
                                                    </UncontrolledTooltip>
                                                    <FormFeedback>
                                                        Please enter a valid date range
                                                    </FormFeedback>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col sm={10}>
                                                    <Button id="clear" onClick={() => {
                                                        return this.clearFilter();
                                                    }} color="danger">Clear</Button>
                                                    <UncontrolledTooltip placement="right" target="clear">
                                                        Clear filter.
                                                    </UncontrolledTooltip>
                                                    <span> </span>
                                                    <Button id="apply" disabled={!this.state.filterChanged} onClick={() => this.applyFilter()} color="success">Apply</Button>
                                                    <UncontrolledTooltip placement="right" target="apply">
                                                        Apply filter.
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Collapse>
                            <ButtonGroup>
                                <Button
                                    color="secondary"
                                    onClick={() => this.setRSelected(1)}
                                    active={this.state.rSelected === 1}
                                >
                                    Today
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => this.setRSelected(2)}
                                    active={this.state.rSelected === 2}
                                >
                                    Yesterday
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => this.setRSelected(3)}
                                    active={this.state.rSelected === 3}
                                >
                                    This Week
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => this.setRSelected(4)}
                                    active={this.state.rSelected === 4}
                                >
                                    Last Week
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => this.setRSelected(5)}
                                    active={this.state.rSelected === 5}
                                >
                                    This Month
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => this.setRSelected(6)}
                                    active={this.state.rSelected === 6}
                                >
                                    Last Month
                                </Button>
                            </ButtonGroup>
                        </Row>
                        <Row>
                            <Col xs="8" sm="6" md="3">
                                <RequestInfoCard
                                    title="Failed Requests"
                                    count={this.state.documents.length}
                                    icon="fa fa-arrow-right-arrow-left text-info"
                                />
                            </Col>
                            <Col xs="8" sm="6" md="3">
                                <RequestInfoCard
                                    title="Successful Requests"
                                    count={this.state.sucessCount}
                                    icon="fa fa-circle-check text-success"
                                />
                            </Col>
                            <Col xs="8" sm="6" md="3">
                                <RequestInfoCard
                                    title="Failed Requests"
                                    count={this.state.failedCount}
                                    icon="fa fa-circle-exclamation text-danger"
                                />
                            </Col>
                            {this.state.pendingCount > 0 &&
                                <Col xs="8" sm="6" md="3">
                                    <RequestInfoCard
                                        title="Pending Requests"
                                        count={this.state.pendingCount}
                                        icon="fa fa-circle-pause text-info"
                                    />
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col>
                                <ProcessChartCard
                                    loading={this.state.loading}
                                    chartData={chartConfig}
                                    chartOptions={chartOptions}
                                    documentCount={this.state.documents.length}
                                    filterText={this.state.filterText}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <EventsTable
                                    loading={this.state.loading}
                                    documents={this.state.documents}
                                    pageCount={this.state.pageCount}
                                    pageSize={this.state.pageSize}
                                    onViewRequestClick={this.viewRequestModalToggle}
                                    onPrintButtonClick={this.viewLabelModalToggle}
                                    printVisible={true}
                                    settings={settings}
                                />
                            </Col>
                        </Row>
                    </div>
                }
                {this.state.viewRequestModalOpen &&
                    <ViewRequestModal
                        isOpen={this.state.viewRequestModalOpen}
                        toggle={this.viewRequestModalToggle}
                        file={this.state.selectedRequest}
                        settings={settings}
                        displaySubmissionResponse={this.displaySubmissionResponse}
                    />
                }
                {this.state.submissionResponseModalOpen &&
                    <SubmissionResponseModal
                        isOpen={this.state.submissionResponseModalOpen}
                        toggle={this.submissionResponseModalToggle}
                        response={this.state.submissionResponse}
                    />
                }
                {this.state.displayLabelModalOpen &&
                    <ViewLabelModal
                        isOpen={this.state.displayLabelModalOpen}
                        toggle={this.viewLabelModalToggle}
                        file={this.state.selectedRequest}
                    />
                }
                {error &&
                    <ErrorMessageModal
                        isOpen={error && this.state.displayErrorModal}
                        toggle={this.errorModalToggle}
                        error={error}
                    />
                }
            </div>
        );
    }
}


export default Home;