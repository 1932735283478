import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { AuthProvider } from 'react-oidc-context';

import { ApplicationPaths } from 'Contants';
import queryString from 'stringquery';
import Login from 'Login';
import CryptoJS from 'crypto-js';
import { SessionProvider } from './Session';

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      oidcConfig: null,
      clientId: null
    }
  }

  componentWillMount() {
    this.load();
  }

  load = async () => {
      try {

      this.setState({
        loading: true
      })

      var response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl + "/DAT01");

      if (!response.ok) {
        throw new Error("Could not load settings");
      }
      else {
        let responsejson = await response.json();
        this.setState({
          loading: false,
          oidcConfig: responsejson
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    if (this.state.loading || !this.state.oidcConfig) {
      return <Login login={this.load} loading={this.state.loading} />
    }
      return (
          
          <AuthProvider {...this.state.oidcConfig}>
              <SessionProvider>
                  <Layout>
                      <Routes>
                          {AppRoutes.map((route, index) => {
                              const { element, ...rest } = route;
                              return <Route key={index} {...rest} element={element} />;
                          })}
                      </Routes>
                  </Layout>
              </SessionProvider>
          </AuthProvider>
          
    );
  }
}
