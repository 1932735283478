import React, { Component } from 'react';
import { UncontrolledTooltip, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Label, Collapse, Navbar, NavbarToggler, NavItem, Col, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { SessionContext } from '../Session';


class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = SessionContext;


    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            dropDownOpen: false
        };
    }

    toggleCarrier = () => {
        this.setState({
            dropDownOpen: !this.state.dropDownOpen,
        })
    }

    handleCarrierChange = (code) => {
        if (code && code.target && code.target.textContent) {
            //if (props.settings && props.settings.clientId !== code.target.textContent)
            //{
            this.setClientId(code.target.textContent);

            //}
        }
    }


    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    logout = () => {
        localStorage.clear();
        void this.context.auth.signoutRedirect()
    }

    setClientId = async (clientId) => {
        this.context._setClientId(clientId);
    }

    render() {
        const { auth, settings, clientId } = this.context;

        if (auth.isLoading) {
            return <div>Loading...</div>;
        }

        if (auth.error) {
            return <div>Oops... {auth.error.message}</div>;
        }

        if (auth.isAuthenticated) {
            if (settings && settings.datelUser === "True") {
                return (
                    <header>
                        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                            {/* <NavbarBrand tag={Link} to="/">Carrier Connect Dashboard</NavbarBrand> */}
                            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem>
                                        <Col sm={200}>

                                            <a className="text-dark" onClick={() => this.logout()} >{auth.user.profile.name + ' (Datel Support)'}</a>

                                            <Dropdown id="carrierDropDown" isOpen={this.state.dropDownOpen} toggle={this.toggleCarrier}>
                                                <DropdownToggle caret>
                                                    {clientId}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {(settings !== null && settings !== undefined) && (settings.clients !== null && settings.clients !== undefined) ? (
                                                        settings.clients.split(',').map((item, index) => (<DropdownItem key={item} onClick={this.handleCarrierChange}>{item}</DropdownItem>))
                                                    ) : (
                                                        <></>
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                            <UncontrolledTooltip placement="left-end" target="carrierDropDown">
                                                Select client ID
                                            </UncontrolledTooltip>
                                        </Col>
                                    </NavItem>
                                </ul>
                            </Collapse>
                        </Navbar>
                    </header>
                );
            }
            else {
                return (
                    <header>
                        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                            {/* <NavbarBrand tag={Link} to="/">Carrier Connect Dashboard</NavbarBrand> */}
                            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem>
                                        <a className="text-dark" onClick={() => this.logout()} >{auth.user.profile.name}</a>
                                    </NavItem>
                                </ul>
                            </Collapse>
                        </Navbar>
                    </header>
                );
            }
        }
    }
}

export default NavMenu;