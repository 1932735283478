import React, { Component } from "react";
import {
    Container, Col, Form,
    FormGroup, Label, Input,
    Button, Alert
} from 'reactstrap';
import queryString from 'stringquery';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            clientID: "",
            ...props
        };
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    render() {
        return (
            <div className="content">
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-7 col-md-5 col-lg-4">
                        <Form className="form">
                            <Col>
                                <FormGroup>
                                    <Label>Login to continue</Label>
                                    {/*<Input
                                        id="clientID"
                                        placeholder="..."
                                        onChange={this.handleChange}
                                    />*/}
                                </FormGroup>
                            </Col>
                            <Button onClick={() => this.props.login(this.state.clientID)} click>LOGIN</Button>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
export default (Login);