import React, { Component } from 'react';
import { Card, CardBody, CardTitle, ModalHeader, Button, Col, Row, Container } from 'reactstrap';

export class RequestInfoCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                                <i className={this.props.icon} />
                            </div>
                        </Col>
                        <Col md="8" xs="7">
                            <div className="numbers">
                                <p className="card-category">{this.props.title}</p>
                                <CardTitle tag="p">{this.props.count}</CardTitle>
                                <p />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}