import React, { Component } from 'react';
import { UncontrolledTooltip, ButtonGroup, Input, Button, Badge, Form, Label, Table } from 'reactstrap';



class UsersTable extends Component {

    render() {
        const { users, editUserClicked, deleteUserClicked } = this.props;
        return (
            <Table
                hover
                striped
                bordered
            >
                <thead>
                    <tr>
                        <th>Display Name</th>
                        <th>Email</th>
                        <th>Client Id</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => {
                        return (
                            <tr className={"table-info"} style={{ cursor: 'pointer' }}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                    <Badge color="success" pill>
                                        {user.clientId}
                                    </Badge>
                                </td>
                                <td className="text-right">
                                    <ButtonGroup>
                                        <Button onClick={() => editUserClicked(user)} id="view1" style={{ padding: '8px', border: 'none !important' }} color='info'>
                                            <i className="fas fa-pen-to-square" />
                                        </Button>
                                        <UncontrolledTooltip placement="top-start" target="view1">
                                            Edit user info.
                                        </UncontrolledTooltip>
                                        <Button onClick={() => deleteUserClicked(user)} id="view" style={{ padding: '8px', border: 'none !important' }} color='danger'>
                                            <i className="fas fa-trash" />
                                        </Button>
                                        <UncontrolledTooltip placement="top-start" target="view">
                                            Delete User.
                                        </UncontrolledTooltip>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }
}

export default UsersTable;