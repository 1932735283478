import React, { Component } from 'react';
import { Button, Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Sidebar from './Sidebar';
import { useLocation } from "react-router-dom";
import routes from '../AppRoutes';

export function Layout(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("primary");
  const mainPanel = React.useRef();
  const location = useLocation();
  const [isSmall, setIsSmall] = React.useState(false);
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };
  const toggleSize = () => {
    setIsSmall(!isSmall);
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        isSmall={isSmall}
        toggleSize={toggleSize}
      />
      <div
        className={`main-panel ${isSmall ? "large" : ""}`}
        ref={mainPanel}
      >
        <NavMenu />
        <Container>
          {props.children}
        </Container>
      </div>
    </div>
  );
}
