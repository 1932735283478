import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row, Container, Label, Input, Form, FormGroup, Spinner, Alert } from 'reactstrap';
import _ from "lodash";

export class UserDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isloading: true,
            editMode: false,
            isSubmitting: false,
            isSaving: false,
            user: null,
        };
    }

    componentDidMount = () => {
        let user = {};

        if (this.props.user)
            user = this.props.user;

        this.setState({
            isloading: false,
            user: user
        });
    }

    toggleEditMode = () => {
        this.setState({
            editMode: !this.state.editMode
        })

        if (this.state.editMode) {
            this.saveShipment();
        }
    }

    editTextValue = (e) => {
        const { name, value } = e.target;
        let user = this.state.user;
        user[name] = value;

        this.setState({
            user: user
        })
    }

    render() {

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>User Details</ModalHeader>
                <ModalBody>
                    <Container>
                        {this.state.isloading ? <div />
                            :
                            <>
                                <Row>
                                    <Col>
                                        <Form>
                                            <FormGroup>
                                                <>
                                                    <Label>Name</Label>
                                                    <Input
                                                        onChange={this.editTextValue}
                                                        name="name"
                                                        value={this.state.user.name}
                                                    />
                                                    <Label>Email</ Label>
                                                    <Input
                                                        onChange={this.editTextValue}
                                                        name="email"
                                                        value={this.state.user.email}
                                                    />
                                                    <Label>Client Id</Label>
                                                    <Input
                                                        onChange={this.editTextValue}
                                                        name="clientId"
                                                        value={this.state.user.clientId}
                                                    />
                                                </>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </>}
                    </Container>
                    {this.props.error &&
                        <Alert color="danger">{this.props.error}</Alert>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => this.props.saveUser(this.state.user)}>
                        Save
                    </Button>
                    <Button color="secondary" onClick={this.props.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal >
        )
    }
}
