import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row, Container, Label, ButtonGroup, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { DocumentViewer } from 'components/DocumentViewer';

export class ViewLabelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            labelDisplay: false,
            labelData: '',
            labelPages: [],
            pageNumber: 1,
            pageCount: [],
            pageRotation: 0
        };
    }

    componentDidMount = async () => {
        const request = this.props.file.requestShipment;
        const response = this.props.file.responseShipment;
        let labelDisplay = false;
        let labelData;
        let labelPages = [];

        if (response.labels.length > 0) {
            if (response.labels.length > 1) {
                labelData = response.labels[0].labelImage;
                labelPages = response.labels;
                this.setPageCount(response.labels.length)
            } else {
                labelData = response.labels[0].labelImage;
            }
        }

        if (request.labels.labelType == 'PDF')
            labelDisplay = true;
        else if (request.labels.labelType == 'ZPL')
            this.convertToPDF(labelData)

        this.setState({
            labelDisplay,
            labelData,
            labelPages
        })
    }

    convertToPDF = async (labelData) => {
        try {
            const response = await fetch(`label`,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ zpl: labelData })
                });
            const data = await response.json();

            this.setState({
                labelData: data.labelData,
                labelDisplay: true
            })
        } catch (err) {
            console.log(err)
        }
    }


    downloadPdf = (shipmentId, base64String) => {
        // Convert base64 to Blob
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${shipmentId}.pdf`); // Name the file

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(blobUrl);
    };

    setPageCount = (num) => {
        // if page count is set, don't allow to be reset
        if (this.state.pageCount.length == 0) {
            var nums = [];
            for (var i = 0; i < num; i++)
                nums.push(i + 1);

            this.setState({
                pageCount: nums
            })
        }
    }

    goToPageNumber = (num) => {
        if (this.state.labelPages.length > 0) {
            var labelData = this.state.labelPages[num - 1].labelImage;
            this.setState({
                labelData
            })
        } else {
            this.setState({
                pageNumber: num
            })
        }
    }

    goToNextPage = () => {
        var pageNumber = this.state.pageNumber + 1;
        if (this.state.labelPages.length > 0) {
            var labelData = this.state.labelPages[this.state.pageNumber].labelImage;
            this.setState({
                labelData
            })
        }  else {
            this.setState({
                pageNumber
            })
        }
    }

    goToPreviousPage = () => {
        var pageNumber = this.state.pageNumber - 1;
        if (this.state.labelPages.length > 0) {
            var labelData = this.state.labelPages[pageNumber].labelImage;
            this.setState({
                labelData
            })
        }  else {
            this.setState({
                pageNumber
            })
        }
    }

    rotatePageRight = () => {
        this.setState({
            pageRotation: this.state.pageRotation + 90
        })
    }

    rotatePageLeft = () => {
        this.setState({
            pageRotation: this.state.pageRotation - 90
        })
    }

    render() {
        const request = this.props.file.requestShipment;
        const response = this.props.file.responseShipment;
        const { labelDisplay, labelData } = this.state;

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>{'Label: ' + request.sourceShipmentId}</ModalHeader>
                <ModalBody style={{ backgroundColor: '#636363' }}>
                    {labelDisplay ?
                        <DocumentViewer data={'data:application/pdf;base64,' + labelData} rotationAngle={this.state.pageRotation} page={this.state.pageNumber} numberOfPagesChange={this.setPageCount} />
                        :
                        <Label>Document type not supported in viewer</Label>
                    }
                </ModalBody>
                <ModalFooter>
                    {/* <ButtonGroup>
                        <Button onClick={() => this.rotatePageLeft()}>
                            <i className="fas fa-rotate-left mr-2" />
                        </Button>
                        <Button onClick={() => this.rotatePageRight()}>
                            <i className="fas fa-rotate-right mr-2" />
                        </Button>
                    </ButtonGroup> */}
                    <ButtonGroup>
                        <Pagination>
                            <PaginationItem>
                                <PaginationLink
                                    onClick={this.goToPreviousPage}
                                    previous
                                />
                            </PaginationItem>
                            {this.state.pageCount.map((page) => (
                                <PaginationItem>
                                    <PaginationLink onClick={() => this.goToPageNumber(page)}>
                                        {page}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                            <PaginationItem>
                                <PaginationLink
                                    onClick={this.goToNextPage}
                                    next
                                />
                            </PaginationItem>
                        </Pagination>
                    </ButtonGroup>
                    {labelData &&
                        <Button color="info" onClick={() => this.downloadPdf(request.sourceShipmentId, labelData)}>
                            <i style={{ marginRight: '5px' }} className="fas fa-file-arrow-down"></i> Download Label
                        </Button>
                    }
                    <Button color="secondary" onClick={this.props.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal >
        )
    }
}