import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Col, Row, Container } from 'reactstrap';

export class ErrorMessageModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        const error = this.props.error;
        
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} style={{ maxWidth: '40%' }}>
                <ModalHeader toggle={this.props.toggle}>Account Error</ModalHeader>
                <ModalBody>{error.error}</ModalBody>
            </Modal>
        )
    }
}