import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, FormFeedback, Input, Form, FormGroup, Label, Button, ButtonGroup, Card, CardBody, CardFooter, CardTitle, Col, Row, Collapse, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import moment from 'moment';
import { EventsTable } from 'components/EventsTable';
import { CsvDownloadButton } from 'components/CsvDownloadButton'

export class ProcessReportModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} style={{ maxWidth: '40%' }}>
                <ModalHeader toggle={this.props.toggle}>Process Report</ModalHeader>
                <ModalBody>
                    
                    <u>Report Summary</u>
                        <br />
                        <br/>
                    Carrier: {this.props.carrier }
                    <br />
                    Date Range: {this.props.date_from} - {this.props.date_to}
                    <br />
                    Selected Shipments: {this.props.report_data.length}
                    <br/>
                    </ModalBody>
                <ModalFooter>
                    <CsvDownloadButton report_data={this.props.report_data} carrierCode={this.props.carrier} clientId={ this.props.clientId} color="primary" onClick={this.props.toggle}>
                        Process Report
                    </CsvDownloadButton>{' '}
                    <Button color="secondary" onClick={this.props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}